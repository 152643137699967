<template>
  <v-row>
    <v-col class="12">
      <v-card class="mb-2">
        <v-card-text class="pa-2 border-bottom">
          <h3
            class="subtitle blue-grey--text text--darken-2 font-weight-regular"
          >
            Estacionamento
          </h3>
        </v-card-text>
        <v-card-text class="pa-5 border-bottom">
          <v-form id="formDadosEstacionamento">
            <v-row align="center">
              <v-col class="pt-0" cols="12" sm="12" lg="4" md="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="estacionamento"
                  rules="required"
                >
                  <v-select
                    v-model="estacionamento"
                    label="Estacionamento"
                    item-text="descricao"
                    item-value="pubic_id"
                    :items="estacionamentos"
                    :error-messages="errors"
                    @change="handleEstacionamentoSelecionado"
                    return-object
                  />
                </validation-provider>
              </v-col>
              <v-col class="pt-0" cols="12" sm="12" lg="4" md="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="setor"
                  rules="required"
                >
                  <v-select
                    v-model="setor"
                    label="Setor"
                    item-text="descricao"
                    item-value="pubic_id"
                    :items="setores"
                    :error-messages="errors"
                    @change="handleSetorSelecionado"
                    return-object
                  />
                </validation-provider>
              </v-col>
              <v-col class="pt-0" cols="12" sm="12" lg="4" md="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="vaga"
                  rules="required"
                >
                <v-select
                  v-model="agendamento.vaga"
                  label="Vaga"
                  :item-text="(item) => item.descricao"
                  item-value="pubic_id"
                  :items="vagas"
                  :error-messages="errors"
                  return-object
                  :disabled="true"
                />
                </validation-provider>
              </v-col>
            </v-row>
          </v-form>
          <v-row align="center">
            <v-col class="pt-0" cols="12" sm="12">
              <dados-estacionamento
                :setor="setor"
                @selecionaVaga="selecionaVaga"
              >
              </dados-estacionamento>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import DadosEstacionamento from './DadosEstacionamento.vue'

export default {
  inject: {
    isEdicaoTriado: {
      from: 'isEdicaoTriado',
      default: false,
    },
  },

  data() {
    return {
      estacionamento: '',
      setor: '',
      vaga: '',
    }
  },
  components: { DadosEstacionamento },
  computed: {
    ...mapState('agendamentos', ['agendamento', 'estacionamentos', 'setores', 'vagas']),
  },
  methods: {
    ...mapActions('template', ['errorMessage']),
    ...mapActions('agendamentos', [
      'agendamento',
      'atualizaEstacionamentoAgendamento',
      'atualizaSetoresAgendamento',
      'atualizaVagasAgendamento',
    ]),
    ...mapMutations('agendamentos', ['ATUALIZA_AGENDAMENTO']),
    selecionaVaga(vaga) {
      if (vaga.livre && vaga.ativo) {
        this.agendamento.vaga = vaga
        this.ATUALIZA_AGENDAMENTO(this.agendamento)
        if (vaga.setor.bonificada) {
          this.$emit('bonificaServicos')
        }
      } else {
        console.log('OPS!')
      }
    },
    async handleEstacionamentoSelecionado() {
      const params = {}
      if (this.isEdicaoTriado) {
        params['bonificada'] = false
      }
      this.atualizaSetoresAgendamento({
        gate: this.agendamento.gate,
        estacionamento: this.estacionamento,
        params,
      })
    },

    async handleSetorSelecionado() {
      const params = {}
      this.atualizaVagasAgendamento({
        gate: this.agendamento.gate,
        estacionamento: this.estacionamento,
        setor: this.setor,
        params,
      })
      this.$set(this.setor, "vagas", this.vagas);
    },
  },
  async created() {},
}
</script>

<style></style>
